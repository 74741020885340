import { refDataObj } from '@/components/refdata/ref-data'
import evalValue from '@/utils/eval-value'
import {
  Config,
  Fields,
  sum,
  wavg,
  TableGrouperItem,
} from '@/utils/table-grouper'

export const fields: Fields = {
  company: {
    value: 'company',
    id: 'company.id',
    lookup: 'companyId',
    display: 'company.abbr',
    header: {
      text: 'Co',
      value: 'company.abbr',
      class: 'px-2',
      cellClass: 'px-2',
    },
  },
  salesArea: {
    value: 'salesArea',
    id: 'salesArea.id',
    lookup: 'salesAreaId',
    display: 'salesArea.abbr',
    header: {
      text: 'Sales Area',
      value: 'salesArea.abbr',
      class: 'px-2',
      cellClass: 'px-2',
    },
  },
  salesperson: {
    value: 'salesperson',
    id: 'salesperson.id',
    lookup: 'salespersonId',
    display: 'salesperson.username',
    header: {
      text: 'Salesperson',
      value: 'salesperson.username',
      class: 'px-2',
      cellClass: 'px-2',
    },
  },

  commissionQuotaPeriod: {
    value: 'commissionQuotaPeriod',
    id: 'commissionQuotaPeriod.id',
    lookup: 'commissionQuotaPeriodId',
    display: 'commissionQuotaPeriod.abbr',
    header: {
      text: 'Quota Period',
      value: 'commissionQuotaPeriod.abbr',
      align: 'center',
      class: 'px-2',
      cellClass: 'px-2',
    },
  },
  commissionPayPeriod: {
    value: 'commissionPayPeriod',
    id: 'commissionPayPeriod.id',
    lookup: 'commissionPayPeriodId',
    display: 'commissionPayPeriod.abbr',
    header: {
      text: 'Pay Period',
      value: 'commissionPayPeriod.abbr',
      class: 'px-2',
      cellClass: 'px-2 text-no-wrap',
      align: 'center',
    },
  },
  commissionComponent: {
    value: 'commissionComponent',
    id: 'commissionComponent.id',
    lookup: 'commissionComponentId',
    display: 'commissionComponent.abbr',
    header: {
      text: 'Component',
      value: 'commissionComponent.abbr',
      class: 'px-2',
      cellClass: 'px-2',
    },
  },
  commissionComponentGroup: {
    value: 'commissionComponent',
    id: 'commissionComponent.group.id',
    lookup: 'commissionComponentGroupId',
    display: 'commissionComponent.group.abbr',
    header: {
      text: 'Group',
      value: 'commissionComponent.group.abbr',
      class: 'px-2',
      cellClass: 'px-2',
    },
  },
  currency: {
    value: 'currency',
    id: 'currency',
    display: 'currency',
    header: {
      text: 'Currency',
      value: 'currency',
      class: 'px-2',
      cellClass: 'px-2',
    },
  },
}

export const config: Config<TableGrouperItem> = {
  group: [
    fields.currency,
    fields.company,
    fields.salesArea,
    fields.salesperson,
    fields.commissionQuotaPeriod,
    fields.commissionPayPeriod,
  ],
  aggregate: [
    {
      id: 'payoutPostedAmount',
      aggregator: sum('payoutPostedAmount'),
      label: 'Posted',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 border-left-double',
      to: (item, group, where) => ({
        name: 'order-details',
        query: group.reduce(
          (prev, g) => ({
            ...prev,
            [g.lookup || g.value]: evalValue(item, g.id),
          }),
          where
        ),
      }),
    },
    {
      id: 'adjustmentPostedAmount',
      aggregator: sum('adjustmentPostedAmount'),
      label: 'Adj Posted',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 border-left-plus',
      to: (item, group, where) => ({
        name: refDataObj['commissionAdjustments'].route.name,
        query: group.reduce(
          (prev, g) => ({
            ...prev,
            [g.lookup || g.value]: evalValue(item, g.id),
          }),
          where
        ),
      }),
    },
    {
      id: 'totalPostedAmount',
      aggregator: (prev: number, item: TableGrouperItem) =>
        prev + (item.payoutPostedAmount + item.adjustmentPostedAmount),
      label: 'Total Posted',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 text--secondary border-left-equals border-right-dashed',
    },
    {
      id: 'paymentPostedAmount',
      aggregator: sum('paymentPostedAmount'),
      label: 'Payment Posted',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2',
      to: (item, group, where) => ({
        name: refDataObj['commissionPayments'].route.name,
        query: group.reduce(
          (prev, g) => ({
            ...prev,
            [g.lookup || g.value]: evalValue(item, g.id),
          }),
          where
        ),
      }),
    },
    {
      id: 'diffPostedAmount',
      aggregator: (prev: number, item: TableGrouperItem) =>
        prev +
        (item.payoutPostedAmount +
          item.adjustmentPostedAmount -
          item.paymentPostedAmount),
      label: 'Diff Posted',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 text--secondary',
      itemClasses: value => ({
        'error--text': Math.abs(value) > 50,
      }),
    },
    {
      id: 'issuePostedAmount',
      aggregator: sum('issuePostedAmount'),
      label: 'Issue Posted',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 text--secondary',
      to: (item, group, where) => ({
        name: 'issues-table',
        query: group.reduce(
          (prev, g) => ({
            ...prev,
            [g.lookup || g.value]: evalValue(item, g.id),
          }),
          where
        ),
      }),
      itemClasses: (value, item) => ({
        'error--text': Math.abs(value - item.diffPostedAmount) > 50,
      }),
    },
    {
      id: 'payoutAmount',
      aggregator: sum('payoutAmount'),
      label: 'Payout',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 border-left-double',
    },
    {
      id: 'adjustmentPayoutAmount',
      aggregator: sum('adjustmentPayoutAmount'),
      label: 'Adj Payout',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 border-left-plus',
      to: (item, group, where) => ({
        name: refDataObj['commissionAdjustments'].route.name,
        query: group.reduce(
          (prev, g) => ({
            ...prev,
            [g.lookup || g.value]: evalValue(item, g.id),
          }),
          where
        ),
      }),
    },
    {
      id: 'totalPayoutAmount',
      aggregator: (prev: number, item: TableGrouperItem) =>
        prev + (item.payoutAmount + item.adjustmentPayoutAmount),
      label: 'Total Payout',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 text--secondary border-left-equals border-right-dashed',
    },
    {
      id: 'paymentAmount',
      aggregator: sum('paymentAmount'),
      label: 'Payment',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2',
      to: (item, group, where) => ({
        name: refDataObj['commissionPayments'].route.name,
        query: group.reduce(
          (prev, g) => ({
            ...prev,
            [g.lookup || g.value]: evalValue(item, g.id),
          }),
          where
        ),
      }),
    },
    {
      id: 'diffPayoutAmount',
      aggregator: (prev: number, item: TableGrouperItem) =>
        prev +
        (item.payoutAmount + item.adjustmentPayoutAmount - item.paymentAmount),
      label: 'Diff Payout',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 text--secondary',
      itemClasses: value => ({
        'error--text': Math.abs(value) > 5,
      }),
    },
    {
      id: 'issuePayoutAmount',
      aggregator: sum('issuePayoutAmount'),
      label: 'Issue Payout',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 text--secondary',
      to: (item, group, where) => ({
        name: 'issues-table',
        query: group.reduce(
          (prev, g) => ({
            ...prev,
            [g.lookup || g.value]: evalValue(item, g.id),
          }),
          where
        ),
      }),
      itemClasses: (value, item) => ({
        'error--text': Math.abs(value - item.diffPayoutAmount) > 5,
      }),
    },
    {
      id: 'effectivePayoutRate',
      initial: [0, 0],
      aggregator: wavg('payoutAmount', 'payoutPostedAmount'),
      totalAggregator: wavg('payoutAmount', 'payoutPostedAmount'),
      calc: (prev: [number, number]) => prev[0] / prev[1],
      label: 'Eff Rate',
      formatter: () => ['percent', 3],
      class: 'px-2',
      cellClass: 'px-2 border-left-double',
    },

    {
      id: 'additionalEstPayoutPostedAmount',
      aggregator: (prev: number, item: TableGrouperItem) =>
        prev +
        ((item.estPayoutPostedAmount || 0) - (item.payoutPostedAmount || 0)),
      label: 'Add Est Posted',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2 border-left-double',
      to: (item, group, where) => ({
        name: 'order-details',
        query: group.reduce(
          (prev, g) => ({
            ...prev,
            [g.lookup || g.value]: evalValue(item, g.id),
            isPosted: '0', // false
          }),
          where
        ),
      }),
    },
    {
      id: 'additionalEstPayoutAmount',
      aggregator: (prev: number, item: TableGrouperItem) =>
        prev + ((item.estPayoutAmount || 0) - (item.payoutAmount || 0)),
      label: 'Add Est Payout',
      formatter: items => ['currency', items[0] ? items[0].currency : ''],
      class: 'px-2',
      cellClass: 'px-2',
    },
  ],
}
