var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"commission-payout-table",attrs:{"items":_vm.groupedItems,"headers":_vm.headers,"expanded":_vm.expanded,"item-key":"id","show-expand":_vm.hasChildGroups,"disable-sort":"","items-per-page":-1,"sort-by":"commissionPayPeriod.abbr","hide-default-footer":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.commissionPayPeriod.abbr",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(value))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(value)+" ("+_vm._s(item.commissionPayPeriod.startDate)+" to "+_vm._s(item.commissionPayPeriod.endDate)+") ")])])]}},{key:"item.cumulativeTotalPosted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.calcCumulative( item, function (v) { return v.payoutPostedAmount + v.adjustmentPostedAmount; } ),item.currency))+" ")]}},{key:"item.cumulativeTotalPayout",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.calcCumulative(item, function (v) { return v.payoutAmount + v.adjustmentPayoutAmount; }),item.currency))+" ")]}},_vm._l((_vm.config.aggregate),function(aggregateField){return {key:_vm.getAggregateFieldSlotName(aggregateField),fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(aggregateField.to)?_c('router-link',{key:aggregateField.id,class:aggregateField.itemClasses
          ? aggregateField.itemClasses(value, item, _vm.calcGroup)
          : {},attrs:{"to":aggregateField.to(item, _vm.calcGroup, _vm.where)}},[_vm._v(" "+_vm._s(_vm.doFormat(value, aggregateField.formatter, [item]))+" ")]):_c('span',{key:aggregateField.id,class:aggregateField.itemClasses
          ? aggregateField.itemClasses(value, item, _vm.calcGroup)
          : {}},[_vm._v(" "+_vm._s(_vm.doFormat(value, aggregateField.formatter, [item]))+" ")])]}}}),{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [(_vm.showBullet)?_c('td',{staticClass:"px-1 text-center"},[_c('v-icon',[_vm._v("mdi-menu-right")])],1):_vm._e(),_c('td',{staticClass:"px-2 py-2",attrs:{"colspan":_vm.headers.length + (_vm.showBullet ? 0 : 1)}},[_c('commission-payout-table',{attrs:{"items":_vm.filterItems(item),"where":_vm.where,"all-items":_vm.allItems,"group":_vm.group.concat( [_vm.calcChildGroups[0]]),"child-groups":_vm.childGroups.slice(1),"hide-columns":_vm.hideColumns}})],1)]}},{key:"body.append",fn:function(ref){
          var headers = ref.headers;
          var items = ref.items;
return [_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,staticClass:"px-2",class:{
          'text-right': header.align === 'end',
        }},[(header.aggregator)?[_vm._v(" "+_vm._s(_vm.doFormat( header.calc( items.reduce(header.totalAggregator, header.initial) ), header.formatter, items ))+" ")]:_vm._e()],2)}),0)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }