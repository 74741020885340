import { DataTableHeader } from 'vuetify'

import { evalValue } from '@/utils/eval-value'
import {
  CommissionComponent,
  CommissionPayPeriod,
  CommissionQuotaPeriod,
  Company,
  DateOnly,
  SalesArea,
  Salesperson,
} from '@/types'
import { RawLocation } from 'vue-router'

export interface TableGrouperItem {
  adjustmentPayoutAmount: number
  adjustmentPostedAmount: number
  commissionComponent: CommissionComponent
  commissionPayPeriod: CommissionPayPeriod
  commissionPayPeriodEndDate: DateOnly
  commissionPayPeriodStartDate: DateOnly
  commissionQuotaPeriod: CommissionQuotaPeriod
  company: Company
  cumulativeEstPayoutAmount: number
  cumulativePayoutAmount: number
  currency: string
  effectiveEstPayoutRate: number
  effectivePayoutRate: number
  estPayoutAmount: number
  estPayoutPostedAmount: number
  issuePayoutAmount: number
  issuePostedAmount: number
  paymentAmount: number
  paymentPostedAmount: number
  payoutAmount: number
  payoutPostedAmount: number
  salesArea: SalesArea
  salesperson: Salesperson

  diffPayoutAmount: number
  diffPostedAmount: number
}

export interface TableGroupItem {
  [key: string]: number | string | [number, number]
}

export const sum = <T>(prop: string) => (prev: number, item: T): number =>
  prev + evalValue(item, prop)

export const wavg = <T>(numerator: string, denominator: string) => (
  prev: [number, number],
  item: T
): [number, number] => [
  prev[0] + evalValue(item, numerator),
  prev[1] + evalValue(item, denominator),
]

type SimpleAggregator<T = TableGrouperItem> = (prev: number, item: T) => number
type ArrayAggregator<T = TableGrouperItem> = (
  prev: [number, number],
  item: T
) => [number, number]
export type Aggregator<T = TableGrouperItem> =
  | SimpleAggregator<T>
  | ArrayAggregator<T>
type CurrencyFormatter<T = TableGrouperItem> = (
  items: T[]
) => ['currency', string]
type PercentFormatter<T = TableGrouperItem> = (
  items: T[]
) => ['percent', number]
export type Formatter<T = TableGrouperItem> =
  | CurrencyFormatter<T>
  | PercentFormatter<T>
type AggregateConfig<T> = {
  id: string
  label: string
  aggregator: Aggregator
  totalAggregator?: Aggregator<TableGroupItem>
  formatter: Formatter
  class: string
  cellClass: string
  initial?: number | [number, number]
  calc?: (prev: [number, number]) => number
  to?: (item: T, group: Field[], where: Where) => RawLocation
  itemClasses?: (
    value: number,
    item: T,
    group: Field[]
  ) => { [key: string]: boolean }
}

export type Config<T = TableGrouperItem> = {
  group: Array<Field>
  aggregate: AggregateConfig<T>[]
}

export type Where = {
  [key: string]: string | string[] | (string | null)[] | null | undefined // string[]
}

export type Field = {
  id: string
  aggregator?: (prev: number, item: unknown) => number
  value: string
  display: string
  header: DataTableHeader
  lookup?: string
}

export type Fields = {
  [prop: string]: Field
}

export const doGroup = <T extends TableGrouperItem>(
  items: T[],
  config: Config
): TableGroupItem[] => {
  return items
    .reduce((prev, item: T) => {
      // find existing grouping
      let z = prev.find(zi =>
        config.group.every(
          fieldObj =>
            evalValue(zi, fieldObj.id) === evalValue(item, fieldObj.id)
        )
      )

      if (!z) {
        // create new grouping
        z = {
          ...config.group.reduce(
            (prev, g) => ({
              ...prev,
              [g.value]: item[g.value as keyof typeof item],
            }),
            {}
          ),
          ...config.aggregate.reduce(
            (prev, a) => ({
              ...prev,
              [a.id]: a.initial === undefined ? 0 : a.initial,
            }),
            {}
          ),
        } as TableGroupItem

        prev.push(z)
      }

      // do aggregation
      config.aggregate.forEach(a => {
        if (z && a.id in z) z[a.id] = a.aggregator(z[a.id] as any, item) // eslint-disable-line @typescript-eslint/no-explicit-any
      })

      return prev
    }, [] as TableGroupItem[])
    .map(z => ({
      ...z,
      ...config.aggregate.reduce(
        (prev, a) => ({
          ...prev,
          [a.id]: a.calc ? a.calc(z[a.id] as [number, number]) : z[a.id],
        }),
        {}
      ),
    }))
}
