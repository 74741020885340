





























































































































import Vue from 'vue'
import log from '@/log'

// components
import CommissionPayoutTable from '@/components/dashboard/payout/partials/CommissionPayoutTable.vue'
import JQueryChips from '@/components/controls/JQueryChips.vue'

// table data
import { ID } from '@/types'
import { TableGrouperItem, Where } from '@/utils/table-grouper'

type FilterFn = (
  values: (string | number)[]
) => (item: TableGrouperItem) => boolean
type GroupBy = {
  id: string
  lookup: string
  name: string
  groupAlways: boolean
  filterable?: boolean
  items?: (values: TableGrouperItem[]) => unknown
  itemText?: string
  itemValue?: string
  castId?: (v: string) => string | number
  filter?: FilterFn
}

const URL_DELIMITER = '|'

export default Vue.extend({
  metaInfo: {
    title: 'Payout - Dashboard',
  },
  components: {
    CommissionPayoutTable,
    JQueryChips,
  },
  props: {
    period: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data: () => ({
    items: [] as TableGrouperItem[],

    dialog: false,

    isFetching: false,
    errors: [] as Error[],
  }),
  watch: {
    period: {
      immediate: true,
      handler() {
        this.getData()
      },
    },
  },
  computed: {
    filteredItems(): TableGrouperItem[] {
      return this.items.filter(item =>
        this.filters.every(filter => filter(item))
      )
    },
    filters(): Array<(item: TableGrouperItem) => boolean> {
      return this.groupBys
        .filter(
          field =>
            field.filterable &&
            field.filter &&
            Object.prototype.hasOwnProperty.call(this.$route.query, field.id) &&
            this.getRouteValue(field).length > 0
        )
        .map(field =>
          field.filter ? field.filter(this.getRouteValue(field)) : () => true
        )
    },
    groupBys(): GroupBy[] {
      return [
        {
          id: 'currency',
          lookup: 'currency',
          name: 'Currency',
          groupAlways: true,
          filterable: true,
          items: values => values.map(v => v.currency),
          itemText: undefined,
          itemValue: undefined,
          filter: (values: (string | number)[]) => (item: TableGrouperItem) =>
            values.includes(item.currency),
        },
        {
          id: 'commissionQuotaPeriod',
          lookup: 'commissionQuotaPeriodId',
          name: 'Commission Quota Period',
          groupAlways: true,
        },
        {
          id: 'commissionPayPeriod',
          lookup: 'commissionPayPeriodId',
          name: 'Commission Pay Period',
          groupAlways: true,
          filterable: true,
          items: values => values.map(v => v.commissionPayPeriod),
          itemText: 'abbr',
          itemValue: 'id',
          castId: v => parseInt(v),
          filter: (values: (string | number)[]) => (item: TableGrouperItem) =>
            values.includes(item.commissionPayPeriod.id),
        },
        {
          id: 'company',
          lookup: 'companyId',
          name: 'Company',
          groupAlways: true,
          filterable: true,
          items: values => values.map(v => v.company),
          itemText: 'abbr',
          itemValue: 'id',
          castId: v => parseInt(v),
          filter: (values: (string | number)[]) => (item: TableGrouperItem) =>
            values.includes(item.company.id),
        },
        {
          id: 'salesArea',
          lookup: 'salesAreaId',
          name: 'Sales Area',
          groupAlways: false,
          filterable: true,
          items: values => values.map(v => v.salesArea),
          itemText: 'abbr',
          itemValue: 'id',
          castId: v => parseInt(v),
          filter: (values: (string | number)[]) => (item: TableGrouperItem) =>
            values.includes(item.salesArea.id),
        },
        {
          id: 'salesperson',
          lookup: 'salespersonId',
          name: 'Salesperson',
          groupAlways: false,
          filterable: true,
          items: values => values.map(v => v.salesperson),
          itemText: 'username',
          itemValue: 'id',
          castId: v => parseInt(v),
          filter: (values: (string | number)[]) => (item: TableGrouperItem) =>
            values.includes(item.salesperson.id),
        },
        {
          id: 'commissionComponentGroup',
          lookup: 'commissionComponentGroupId',
          name: 'Commission Component Group',
          groupAlways: false,
          filterable: true,
          items: values => values.map(v => v.commissionComponent.group),
          itemText: 'abbr',
          itemValue: 'id',
          castId: v => parseInt(v),
          filter: (values: (string | number)[]) => (item: TableGrouperItem) =>
            values.includes(item.commissionComponent.group.id),
        },
        {
          id: 'commissionComponent',
          lookup: 'commissionComponentId',
          name: 'Commission Component',
          groupAlways: false,
          filterable: true,
          items: values => values.map(v => v.commissionComponent),
          itemText: 'abbr',
          itemValue: 'id',
          castId: v => parseInt(v),
          filter: (values: (string | number)[]) => (item: TableGrouperItem) =>
            values.includes(item.commissionComponent.id),
        },
      ]
    },
    where(): Where {
      return Object.entries(this.$route.query || {})
        .map(([key, value]) => [this.groupBys.find(g => g.id === key), value])
        .filter((obj): obj is [GroupBy, string] => !!obj[0])
        .reduce(
          (prev, [groupBy, value]) => ({
            ...prev,
            [groupBy.lookup]: value,
          }),
          {}
        )
    },
    group(): string[] {
      const g = this.$route.query['group']
      return (!g ? [] : Array.isArray(g) ? g : g.split(URL_DELIMITER)).filter(
        (v): v is string => v !== null
      )
    },
  },
  methods: {
    getRouteValue(field: GroupBy): (string | number)[] {
      const val = this.$route.query[field.id] || ''
      return (Array.isArray(val)
        ? val.filter((v): v is string => v !== null)
        : val.split(URL_DELIMITER)
      )
        .filter(v => !!v)
        .map(field.castId || (v => v))
    },
    updateQuery(key: string, value: string): void {
      const query = Object.entries(
        Object.assign({}, this.$route.query, { [key]: value })
      )
        .filter(([, value]) => value !== '')
        .reduce(
          (prev, [key, value]) => ({
            ...prev,
            [key]: value,
          }),
          {}
        )

      this.$router.replace({ query })
    },
    onGroupChange(v: string[]): void {
      this.updateQuery('group', v.join(URL_DELIMITER))
    },
    onFilterChange(fieldId: string) {
      return (v: ID[]) => this.updateQuery(fieldId, v.join(URL_DELIMITER))
    },
    getData(): void {
      this.isFetching = true
      this.errors = []

      this.$api.commissions.payouts.periods
        .period(this.period)
        .list()
        .then(items => {
          this.items = items
        })
        .catch(err => {
          log.error(err)
          this.errors.push(err)
        })
        .finally(() => {
          this.isFetching = false
        })
    },
  },
})
