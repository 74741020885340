




















































































































import Vue, { PropType } from 'vue'
import { DataTableHeader } from 'vuetify'

// table data
import { config, fields } from '../table-data'

// utils
import {
  Config,
  Field,
  doGroup,
  TableGrouperItem,
  TableGroupItem,
  Formatter,
  Where,
} from '@/utils/table-grouper'
import { isString } from '@/utils/type-helpers'
import { sortAlpha } from '@/utils/sort'
import evalValue from '@/utils/eval-value'

const { aggregate } = config

const normalizeGroup = (g: string | Field) => (isString(g) ? fields[g] : g)

function getId<T>(group: Field[], item: T) {
  return group.map(g => evalValue(item, g.id)).join(':')
}

export default Vue.extend({
  name: 'CommissionPayoutTable',
  props: {
    items: Array as PropType<TableGrouperItem[]>,
    allItems: Array as PropType<TableGrouperItem[]>,
    where: {
      type: Object as PropType<Where>,
      default: () => ({}),
    },
    group: {
      type: Array as PropType<Array<string | Field>>,
      required: true,
    },
    childGroups: {
      type: Array as PropType<Array<string | Field>>,
      default: () => [],
    },
    hideColumns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showBullet: Boolean,
  },
  data: () => ({
    expanded: [],
  }),
  computed: {
    calcGroup(): Field[] {
      return this.group.map(normalizeGroup)
    },
    hasChildGroups(): boolean {
      return this.childGroups && this.childGroups.length > 0
    },
    calcChildGroups(): Field[] {
      return this.childGroups.map(normalizeGroup)
    },
    config(): Config {
      return {
        group: this.calcGroup,
        aggregate,
      }
    },
    groupedItems(): TableGroupItem[] {
      return doGroup(this.items, this.config)
        .map(z => ({
          ...z,
          id: getId(this.config.group, z),
        }))
        .sort(sortAlpha('commissionPayPeriod.startDate'))
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: '',
          value: 'data-table-expand',
          align: 'center',
          class: 'px-0',
          cellClass: 'pl-1 pr-0',
        },

        ...this.calcGroup.map(g => g.header),

        ...aggregate.map(a => ({
          text: a.label,
          value: a.id,
          align: 'end',
          aggregator: a.aggregator,
          totalAggregator:
            a.totalAggregator ||
            ((prev: number, item: TableGroupItem) => prev + Number(item[a.id])),
          calc: a.calc || ((prev: number) => prev),
          initial: a.initial || 0,
          formatter: a.formatter || (() => ['number']),
          class: a.class,
          cellClass: a.cellClass,
        })),

        {
          text: 'Cumulative Total Posted',
          value: 'cumulativeTotalPosted',
          align: 'end',
          class: 'px-2',
          cellClass: 'px-2 border-left-double',
        },
        {
          text: 'Cumulative Total Payout',
          value: 'cumulativeTotalPayout',
          align: 'end',
          class: 'px-2',
          cellClass: 'px-2',
        },
      ].filter(h => !this.hideColumns.includes(h.value)) as DataTableHeader[]
    },
  },
  methods: {
    calcCumulative(
      tableItem: TableGrouperItem,
      valueGetter: (v: TableGrouperItem) => number
    ): number {
      const filterTests = [
        ...this.calcGroup
          .filter(v => v.value !== 'commissionPayPeriod')
          .map(v => v.id)
          .map(prop => (item: TableGrouperItem) =>
            evalValue(item, prop) === evalValue(tableItem, prop)
          ),
        (item: TableGrouperItem) =>
          item.commissionPayPeriod.startDate <=
          tableItem.commissionPayPeriod.startDate,
      ]

      const filteredItems = this.allItems.filter(item =>
        filterTests.every(fn => fn(item))
      )

      return filteredItems.reduce(
        (prev, item) => prev + (valueGetter(item) || 0),
        0
      )
    },
    doFormat(
      value: number,
      formatter: Formatter,
      items: TableGrouperItem[],
      nan = ''
    ): string {
      if (!formatter || !this.$options.filters || items.length <= 0)
        return value.toString()
      if (Number.isNaN(value)) return nan
      return this.$options.filters[formatter(items)[0]](
        value,
        ...formatter(items).slice(1)
      )
    },
    getAggregateFieldSlotName(aggregate: Field) {
      return `item.${aggregate.id}`
    },
    filterItems(item: TableGrouperItem): TableGrouperItem[] {
      return this.items.filter(z =>
        this.config.group.every(
          g => evalValue(z, g.id) === evalValue(item, g.id)
        )
      )
    },
  },
})
